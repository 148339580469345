import { createContext, useEffect, useState } from "react";

const LikeContext = createContext();

export const LikeContextProvider = ({ children }) => {
  const [blogsLength, setBlogLength] = useState(0);
  const [liked, setLiked] = useState(
    JSON.parse(localStorage.getItem("liked")) || Array(blogsLength).fill("")
  );

  const handleUpdateElement = (index, newValue) => {
    const temp = [...liked];
    temp[index] = newValue;
    setLiked(temp);
  };

  useEffect(() => {
    localStorage.setItem("liked", JSON.stringify(liked));
  }, [liked]);

  const value = { setBlogLength, liked, handleUpdateElement, blogsLength };

  return <LikeContext.Provider value={value}>{children}</LikeContext.Provider>;
};
export default LikeContext;
