import { useEffect, useState } from "react";
import "./projects.scss";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import LoadScreen from "../../components/loadScreen/LoadScreen";
import LazyLoad from "react-lazyload";
import { useSearchParams } from "react-router-dom";

const Projects = () => {
  document.title = "Projects | Yavuz Bilge Ceylan";
  const [projects, setProjects] = useState();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("");
  const filtered = projects && projects.filter(({ tag }) => (activeTab ? tag === activeTab : tag !== ""));
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchData();
    setActiveTab(searchParams.get("tab") || "");
  }, []);

  const handleSelectTab = (tab) => {
    setActiveTab(tab);
    setSearchParams({ tab });
  };

  const fetchData = async () => {
    let list = [];
    try {
      const queryProjects = await getDocs(collection(db, "projects"));
      queryProjects.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
        setProjects(list);
      });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <div className="projects">
      <h1>My Recent Projects</h1>
      <div className="tabs">
        <div className={`tab ${activeTab === "" && "active"}`} onClick={() => handleSelectTab("")}>
          All
        </div>
        <div className={`tab ${activeTab === "product" && "active"}`} onClick={() => handleSelectTab("product")}>
          Product
        </div>
        <div className={`tab ${activeTab === "advanced" && "active"}`} onClick={() => handleSelectTab("advanced")}>
          Advanced
        </div>
        <div className={`tab ${activeTab === "portfolio" && "active"}`} onClick={() => handleSelectTab("portfolio")}>
          Portfolio
        </div>
        <div className={`tab ${activeTab === "clone" && "active"}`} onClick={() => handleSelectTab("clone")}>
          Clone
        </div>
        <div className={`tab ${activeTab === "simple" && "active"}`} onClick={() => handleSelectTab("simple")}>
          Simple
        </div>
      </div>
      {loading ? (
        <LoadScreen />
      ) : (
        <div className="project-list">
          {projects &&
            filtered.map((item, index) => (
              <div className="project-container" key={index}>
                <LazyLoad height={240}>
                  <img src={item.img} alt="" />
                </LazyLoad>
                <div className="title">{item.name}</div>
                {item.url && (
                  <a href={item.url} rel="noreferrer" target="_blank" className="icon " title="Play Demo">
                    <img src="img/play.png" alt="" />
                  </a>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Projects;
