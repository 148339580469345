import "./modal.scss";

export default function Modal({ img, index, setIndex }) {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) setIndex(-1);
  };

  const prevSlide = () => {
    setIndex(index === 0 ? img.length - 1 : (prev) => prev - 1);
  };
  const nextSlide = () => {
    setIndex(index === img.length - 1 ? 0 : (prev) => prev + 1);
  };

  return (
    <div className="modal dismiss " onClick={handleClick}>
      <img src={img[index].src} alt="" className={`img ${img[index].direction} dismiss`} onClick={handleClick} />
      <span className="icon dismiss-icon dismiss" onClick={handleClick}>
        &#215;
      </span>
      <div onClick={prevSlide} className="icon prev">
        <div>&#60;&#60;</div>
      </div>
      <div onClick={nextSlide} className="icon next">
        <div>&#62;&#62;</div>
      </div>
    </div>
  );
}
