import "./postsLoader.scss";

const PostsLoader = () => {
  return (
    <>
      <div className="blog-container df-col loader">
        <div className="img"></div>
        <div className="info df-col ">
          <div className="title"></div>
          <div className="sub-info df-row">
            <div className="tag"></div>
            <div className="date"></div>
          </div>
        </div>
        <div className="text"></div>
      </div>
    </>
  );
};

export default PostsLoader;
