import { useEffect, useState } from "react";
import "./about.scss";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import LoadScreen from "../../components/loadScreen/LoadScreen";

const icons = [
  { src: "img/brain.png", text: "Smart" },
  { src: "img/winner.png", text: "Winner" },
  { src: "img/meeting.png", text: "Team Player" },
  { src: "img/work.png", text: "Hardworking" },
  { src: "img/sports.png", text: "Athlete" },
  { src: "img/innovation.png", text: "Creative" },
  { src: "img/thunder.png", text: "Energetic" },
  { src: "img/no-smoking.png", text: "No Smoking" },
];

const About = () => {
  document.title = "About | Yavuz Bilge Ceylan";
  const [about, setAbout] = useState();
  const [loading, setLoading] = useState(true);
  const age = new Date().getFullYear() - 1998;

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const queryAbout = await getDocs(collection(db, "about"));
        queryAbout.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setAbout(list);
        });
      } catch (err) {
        console.log(err);
      }
    };
    fetchData().finally(() => setLoading(false));
  }, []);

  return (
    <div className="about">
      {loading ? (
        <LoadScreen />
      ) : (
        <>
          <div className="info">
            <h1>Basic Info About Me</h1>
            <div className="summary">
              <div className=" sum-cont">
                <b>Degree</b> <div>Electronics Engineer</div>
              </div>
              <div className=" sum-cont">
                <b>Age</b> <div>{age}</div>
              </div>
              <div className=" sum-cont">
                <b>Hometown</b> <div>Istanbul/Turkey</div>
              </div>
            </div>
            <div className="about-me-text">
              <div style={{ marginBottom: 10 }}>{about[0].content1}</div>
              <div>{about[0].content2}</div>
            </div>
          </div>
          <div className="icons">
            {icons.map((icon, index) => (
              <div className="icon-cont" key={index}>
                <img src={icon.src} alt="" />
                <div className="icon-text">{icon.text}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default About;
