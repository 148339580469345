import "./resume.scss";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { useState } from "react";
import LoadScreen from "../../components/loadScreen/LoadScreen";

const hobies = [
  {
    src: "img/travel.png",
    text: "Traveling",
  },
  {
    src: "img/basketball.png",
    text: "Basketball",
  },
  {
    src: "img/camp.png",
    text: "Camping",
  },
  {
    src: "img/chess.png",
    text: "Strategy Games",
  },
  {
    src: "img/messages.png",
    text: "Chat",
  },
  {
    src: "img/listener.png",
    text: "Listening Music",
  },
];

function Resume() {
  document.title = "Resume | Yavuz Bilge Ceylan";
  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([]);
  const [skillsFrontend, setSkillsFrontend] = useState([]);
  const [skillsBackend, setSkillsBackend] = useState([]);
  const [skillsOthers, setSkillsOthers] = useState([]);
  const [hobiess, setHobiess] = useState([]); //dummy
  const [urlCV, setUrlCV] = useState();
  const [loading, setLoading] = useState(true);
  let list = [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        list = [];
        const queryEducation = await getDocs(collection(db, "education"));
        queryEducation.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setEducation(list);
        });

        list = [];
        const queryExperience = await getDocs(collection(db, "experience"));
        queryExperience.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setExperience(list);
        });

        list = [];
        const queryFrontend = await getDocs(collection(db, "skillsFrontend"));
        queryFrontend.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setSkillsFrontend(list);
        });

        list = [];
        const queryBackend = await getDocs(collection(db, "skillsBackend"));
        queryBackend.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setSkillsBackend(list);
        });

        list = [];
        const queryOthers = await getDocs(collection(db, "skillsOthers"));
        queryOthers.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setSkillsOthers(list);
        });

        list = [];
        const queryUrlCV = await getDocs(collection(db, "urlCV"));
        queryUrlCV.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setUrlCV(list[0].url);
        });

        //dummy, en sonuncu iki kez yazıyo diiye ekledim
        list = [];
        const queryHobies = await getDocs(collection(db, "hobies"));
        queryHobies.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setHobiess(list);
        });
      } catch (err) {
        console.log(err);
      }
    };
    fetchData().finally(() => setLoading(false));
  }, []);

  return (
    <div className="resume">
      <h1>My Resume</h1>

      {loading ? (
        <LoadScreen />
      ) : (
        <>
          {/* <a href={urlCV} target="_blank" className="cv" style={{ marginTop: -80, marginBottom: 40 }}>
            Donwload PDF <span> &raquo;</span>
          </a> */}

          <div className="list">
            <div className="type">Work Experience</div>
            {experience
              .map((item, index) => (
                <div className="details" key={index}>
                  <div className="left">
                    <div className="title">
                      <b> {item.title} </b>
                    </div>
                    <div className="location">@{item.company}</div>
                  </div>
                  <div className="icon"></div>
                  <div className="right">{item.date}</div>
                </div>
              ))
              .reverse()}
          </div>
          <div className="list">
            <div className="type">Education</div>
            {education
              .map((item, index) => (
                <div className="details det-2" key={index}>
                  <div className="left">
                    <div className="title">
                      <b>{item.degree}</b> {item.department}
                    </div>
                    <div className="location">@{item.school}</div>
                  </div>
                  <div className="icon"></div>
                  <div className="right">{item.date}</div>
                </div>
              ))
              .reverse()}
          </div>

          {/* <div className="list">
            <div className="type">Graduated From HighSchool</div>
          </div> */}

          <div className="hobies">
            <h3>My Hobies</h3>
            <div className="container">
              <div className="images">
                {hobies.map((hobie, index) => (
                  <div className="img-cont" key={index}>
                    <img src={hobie.src} alt="" />
                    <div className="text">{hobie.text}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* <div className="skills">
            <h3>My Skills</h3>
            <div className="skills-cont">
              <div className="category">
                <h4>Frontend</h4>
                {skillsFrontend
                  .map((skill, index) => (
                    <div className="skill-cont" data-descr={`${skill.rating}%`} key={index}>
                      <div className="skill">{skill.name}</div>
                      <div className="bar" style={{ width: `${skill.rating}%` }}></div>
                    </div>
                  ))
                  .reverse()}
              </div>
              <div className="category">
                <h4>Backend</h4>
                {skillsBackend
                  .map((skill, index) => (
                    <div className="skill-cont" data-descr={`${skill.rating}%`} key={index}>
                      <div className="skill">{skill.name}</div>
                      <div className="bar" style={{ width: `${skill.rating}%` }}></div>
                    </div>
                  ))
                  .reverse()}
              </div>
              <div className="category">
                <h4>Others</h4>
                {skillsOthers.map((skill, index) => (
                  <div className="skill-cont " data-descr={`${skill.rating}%`} key={index}>
                    <div className="skill">{skill.name}</div>
                    <div className="bar" style={{ width: `${skill.rating}%` }}></div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
}

export default Resume;
