import "./post.scss";
import { AiTwotoneLike, AiTwotoneDislike } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import LikeContext from "../../context/LikeContext";
import { useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import LoadScreen from "../loadScreen/LoadScreen";
import { Tooltip } from "react-tooltip";
import { Link as SmoothScroll } from "react-scroll";

const Post = () => {
  const { liked, handleUpdateElement, blogsLength, setBlogLength } = useContext(LikeContext);
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState();
  const [blogId, setBlogId] = useState();

  let tabTitle = blog ? blog.title : "Loading...";
  document.title = `Post - ${tabTitle}`;

  useEffect(() => {
    let url = window.location.href;
    let index = url.split("/");
    setBlogId(parseInt(index[4].replace("post-", "")));
  }, []);

  useEffect(() => {
    setIsAnimating(false);

    const fetchData = async () => {
      let list = [];
      try {
        const queryBlog = await getDocs(collection(db, "blog"));
        queryBlog.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setBlog(list[blogId]);
          setBlogLength(list.length);
        });
      } catch (err) {
        console.log(err);
      }
    };
    fetchData().finally(() => setLoading(false));
  }, [blogId]);
  const navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);

  const handleAnimation = (e) => {
    setIsAnimating(true);

    setTimeout(() => {
      e.target.getAttribute("id") === "next" && setBlogId((prev) => prev + 1);
      e.target.getAttribute("id") === "prev" && setBlogId((prev) => prev - 1);

      navigate(e.target.getAttribute("to"));
    }, 1000);
  };

  return (
    <div className="post df-col ">
      <div className={`animated-background left-align ${isAnimating && "animation"}`}></div>
      <div className={`animated-background right-align  ${isAnimating && "animation"}`}></div>

      {loading ? (
        <LoadScreen />
      ) : (
        <>
          {blog && (
            <>
              {blog.img && <img src={blog.img} alt="" className="blog-img" />}
              <h2 className="title" id="headerPost" style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <Link to="/blog/posts">
                  <IoMdArrowRoundBack style={{ cursor: "pointer", marginTop: 6 }} id="goBack" />
                </Link>

                {blog.title}
              </h2>
              <div className="sub-info df-col">
                <div className="date">{blog.date}</div>
                <div className="tag">#{blog.tag.replace(/ /g, " #")}</div>
              </div>
              <div className="content df-col">
                <div
                  dangerouslySetInnerHTML={{
                    __html: blog.content,
                  }}
                ></div>
              </div>
              <div className="buttons df-row">
                <SmoothScroll to="headerPost" spy={true} smooth={true} offset={-500} duration={1200}>
                  <div
                    to={`/blog/post-${parseInt(blog.id) - 1}`}
                    id="prev"
                    className="nextBtn "
                    style={{ visibility: blogId === 0 ? "hidden" : "visible" }}
                    onClick={(e) => handleAnimation(e)}
                  >
                    &#171; Prev Post
                  </div>
                </SmoothScroll>
                <AiTwotoneLike
                  id={`like-${blogId}`}
                  className={`icon ${liked[blogId] === "liked" ? "liked" : ""}`}
                  onClick={() =>
                    liked[blogId] === "liked" ? handleUpdateElement(blogId, "") : handleUpdateElement(blogId, "liked")
                  }
                />
                <AiTwotoneDislike
                  id={`dislike-${blogId}`}
                  className={`icon ${liked[blogId] === "disliked" ? "disliked" : ""}`}
                  onClick={() =>
                    liked[blogId] === "disliked"
                      ? handleUpdateElement(blogId, "")
                      : handleUpdateElement(blogId, "disliked")
                  }
                />
                <SmoothScroll to="headerPost" spy={true} smooth={true} offset={-500} duration={1200}>
                  <div
                    to={`/blog/post-${parseInt(blog.id) + 1}`}
                    id="next"
                    className="nextBtn"
                    style={{ visibility: blogId === blogsLength - 1 ? "hidden" : "visible" }}
                    onClick={(e) => handleAnimation(e)}
                  >
                    Next Post &#187;
                  </div>
                </SmoothScroll>
              </div>
              <Link to="/blog/posts" className="goBack">
                Go back to blog list
              </Link>

              <Tooltip anchorId="goBack" place="bottom" content="Go back to blog list" />
              {/* <Tooltip anchorId={`like-${blog.id}`} place="bottom" content="Like" />
              <Tooltip anchorId={`dislike-${blog.id}`} place="bottom" content="Dislike" /> */}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Post;
