import { BsFillFolderFill, BsClockHistory, BsSearch } from "react-icons/bs";
import { BiCategoryAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Link as SmoothScroll } from "react-scroll";

const categories = ["react", "css", "html", "javascript"];
const archieves = ["2022", "2023"];

function Category({ setActiveTab, blogs, setFilterType, setSearchInput, activeTab }) {
  const [archieve2022, setArchieve2022] = useState(0);
  const [archieve2023, setArchieve2023] = useState(0);
  const [input, setInput] = useState("");
  const dataLength = blogs.length;
  const recentPostArray = [1, 2, 3, 4];

  const handleClick = (e) => {
    e.preventDefault();
    let id = e.target.getAttribute("id");

    if (id === "category") {
      setFilterType(id);
      setActiveTab(e.target.getAttribute("name"));
    } else if (id === "archieve") {
      setFilterType(id);
      setActiveTab(e.target.getAttribute("name"));
    }
  };

  useEffect(() => {
    setFilterType("search");
    setSearchInput(input);
  }, [input]);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    setArchieve2022(0);
    setArchieve2023(0);
    blogs.map((blog) => {
      blog.date.split(".")[2] === "2022" ? setArchieve2022((prev) => prev + 1) : setArchieve2023((prev) => prev + 1);
      return 0;
    });
    const onScroll = () => {
      window.scrollY > 700 ? setScrolled(true) : setScrolled(false);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <form onSubmit={input.length > 2 ? handleClick : null} className="search df-row">
        <input type="text " placeholder="Search" value={input} onChange={(e) => setInput(e.target.value)} />
        <BsSearch className="searchIcon" />
      </form>

      <div className="category">
        <h3 className="df-row">
          <BiCategoryAlt />
          <span>Category</span>
        </h3>
        <div className="tabs">
          {categories.map((cat, index) => (
            <div
              key={index}
              className="tab"
              id="category"
              name={cat}
              onClick={handleClick}
              style={{ color: activeTab === cat && "red" }}
            >
              {cat}
            </div>
          ))}
        </div>
      </div>

      <div className="category">
        <h3 className="df-row">
          <BsClockHistory />
          <span>Recent Posts</span>
        </h3>
        <div className="tabs">
          {recentPostArray.map((dummy, index) => (
            <Link
              key={index}
              to={`/blog/post-${blogs[dataLength - index - 1].id}/${blogs[dataLength - index - 1].title.replace(
                / /g,
                "-"
              )}`}
              className="tab"
            >
              {blogs[dataLength - index - 1].title.length > 17
                ? blogs[dataLength - index - 1].title.substring(0, 15) + "..."
                : blogs[dataLength - index - 1].title}
            </Link>
          ))}
        </div>
      </div>

      <div className="category">
        <h3 className="df-row">
          <BsFillFolderFill />
          <span>Archieve</span>
        </h3>
        <div className="tabs">
          {archieves.map((archieve, index) => (
            <div
              key={index}
              className="tab"
              id="archieve"
              name={archieve}
              onClick={handleClick}
              style={{ color: activeTab === archieve && "red" }}
            >
              {archieve}
              {archieve === "2022" && <>({archieve2022})</>}
              {archieve === "2023" && <>({archieve2023})</>}
            </div>
          ))}
        </div>
      </div>

      <SmoothScroll
        to="header"
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
        className="category moveTop"
        style={{ display: scrolled ? "block" : "none" }}
      >
        Move up
      </SmoothScroll>
    </>
  );
}

export default Category;
