import "./loadScreen.scss";

const LoadScreen = () => {
  return (
    <div className="loadScreen">
      <div className="lds-hourglass"></div>
    </div>
  );
};

export default LoadScreen;
