import "./home.scss";
import { useNavigate } from "react-router-dom";
import {
  FaFacebookF,
  FaSpotify,
  FaTwitter,
  FaInstagram,
  FaGithub,
  FaLinkedinIn,
  FaRegNewspaper,
  FaRegAddressCard,
} from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineFolderOpen, AiOutlinePicture, AiOutlineMail } from "react-icons/ai";
import { useState } from "react";
import { Tooltip } from "react-tooltip";

const Home = () => {
  document.title = "Home | Yavuz Bilge Ceylan";
  const navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);

  const handleAnimation = (navigateTo) => {
    setIsAnimating(true);
    setTimeout(() => {
      navigate(navigateTo);
    }, 1000);
  };

  return (
    <div className="home">
      <div className="lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <div className="container">
        <div className={`animated-background ${isAnimating && "animation"}`}></div>
        <div className="img-cont">
          <img src="img/ybc.jpg" alt="" className="ybc-img" />
        </div>
        <div className="list info" style={{ gap: 6 }}>
          <div className="icon-cont" onClick={() => handleAnimation("/about")} id="about">
            <AiOutlineUser className="icon" />
          </div>
          <div className="icon-cont" onClick={() => handleAnimation("/resume")} id="resume">
            <FaRegAddressCard className="icon" />
          </div>
          <div className="icon-cont" onClick={() => handleAnimation("/portfolio")} id="projects">
            <AiOutlineFolderOpen className="icon" />
          </div>
          <div className="icon-cont" onClick={() => handleAnimation("/blog/posts")} id="blog">
            <FaRegNewspaper className="icon" />
          </div>
          <div className="icon-cont" onClick={() => handleAnimation("/gallery")} id="gallery">
            <AiOutlinePicture className="icon" />
          </div>
          <div className="icon-cont" onClick={() => handleAnimation("/contact")} id="contact">
            <AiOutlineMail className="icon" />
          </div>
        </div>
        {/* <div className="list social">
          <a
            id="linkedin"
            href="https://www.linkedin.com/in/yavuz-bilge-ceylan-0753751a3/"
            className="icon-cont"
            rel="noreferrer"
            target="_blank"
          >
            <FaLinkedinIn className="icon" />
          </a>
          <a id="github" href="https://github.com/yvzblgcyln" className="icon-cont" rel="noreferrer" target="_blank">
            <FaGithub className="icon" />
          </a>
          <a
            id="instagram"
            href="https://www.instagram.com/yvzblgcyln/"
            className="icon-cont"
            rel="noreferrer"
            target="_blank"
          >
            <FaInstagram className="icon" />
          </a>
          <a
            id="spotify"
            href=" https://open.spotify.com/user/9g5ulspsjrd89pytx7ke402cq"
            className="icon-cont"
            rel="noreferrer"
            target="_blank"
          >
            <FaSpotify className="icon" />
          </a>

          <a id="twitter" href="https://twitter.com/yvzblgcyln" className="icon-cont" rel="noreferrer" target="_blank">
            <FaTwitter className="icon" />
          </a>
          <a
            id="facebook"
            href="https://www.facebook.com/yvzblgcyln"
            className="icon-cont"
            rel="noreferrer"
            target="_blank"
          >
            <FaFacebookF className="icon" />
          </a>
        </div> */}
        <div className="header header-1">Software Engineer</div>
        <div className="header header-2">Frontend Developer</div>
        <div className="header header-3">Yavuz Bilge Ceylan</div>
      </div>
      {/* <div className="ybc">Yavuz Bilge Ceylan</div> */}
      <Tooltip anchorId="linkedin" place="top" content="Linkedin" />
      <Tooltip anchorId="github" place="top" content="Github" />
      <Tooltip anchorId="instagram" place="top" content="Instagram" />
      <Tooltip anchorId="spotify" place="top" content="Spotify" />
      <Tooltip anchorId="twitter" place="top" content="Twitter" />
      <Tooltip anchorId="facebook" place="top" content="Facebook" />
      <Tooltip anchorId="about" place="top" content="About Me" />
      <Tooltip anchorId="blog" place="top" content="Blog" />
      <Tooltip anchorId="projects" place="top" content="Projects" />
      <Tooltip anchorId="gallery" place="top" content="Gallery" />
      <Tooltip anchorId="resume" place="top" content="Resume" />
      <Tooltip anchorId="contact" place="top" content="Contact" />
    </div>
  );
};

export default Home;
