import "./navbar.scss";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import DarkModeContext from "../../context/DarkModeContext";
import { MdDarkMode } from "react-icons/md";
import { BsSunFill } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { useState } from "react";

const Navbar = () => {
  const navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);
  const { swapDarkMode, darkMode } = useContext(DarkModeContext);

  const handleClick = () => swapDarkMode();

  const handleAnimation = () => {
    setIsAnimating(true);
    setTimeout(() => {
      navigate("/");
    }, 750);
  };

  return (
    <div className={`navbar df-row ${isAnimating && "animated-background-navbar"}`}>
      <div className="theme df-row" onClick={handleClick} id="icon">
        <div
          className="icon"
          style={{ transform: darkMode && "translateX(40px)", color: darkMode && "rgb(8, 41, 78)" }}
        >
          {darkMode ? <MdDarkMode className="moon" /> : <BsSunFill className="sun" />}
        </div>
      </div>
      <div className="container" onClick={handleAnimation}>
        <div>menu </div>
      </div>
      <Tooltip anchorId="icon" place="right" content="Change Theme" />
    </div>
  );
};

export default Navbar;
