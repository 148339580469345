import "./app.css";
import "react-tooltip/dist/react-tooltip.css";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import Blog from "./pages/blog/Blog";
import Contact from "./pages/contact/Contact";
import Resume from "./pages/resume/Resume";
import Projects from "./pages/projects/Projects";
import Gallery from "./pages/gallery/Gallery";
import Post from "./components/post/Post";
import DarkModeContext from "./context/DarkModeContext";
import { useContext } from "react";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const Layout = () => {
    return (
      <>
        <Navbar />
        <div className="outlet">
          <Outlet />
        </div>
        <Footer />
      </>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/about", element: <About /> },
        { path: "/blog/posts", element: <Blog /> },
        { path: "/blog/:id/:title", element: <Post /> },
        { path: "/blog/:id/", element: <Post /> },
        { path: "/contact", element: <Contact /> },
        { path: "/portfolio", element: <Projects /> },
        { path: "/resume", element: <Resume /> },
        { path: "/gallery", element: <Gallery /> },
      ],
    },
  ]);

  return (
    <div className={`app theme-${darkMode ? "dark" : "light"}`}>
      <RouterProvider router={router} />
    </div>
  );
}
export default App;
