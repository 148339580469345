import { useEffect, useState } from "react";
import Modal from "../../components/modal/Modal";
import "./gallery.scss";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import LoadScreen from "../../components/loadScreen/LoadScreen";
import LazyLoad from "react-lazyload";

export default function Gallery() {
  document.title = "Gallery | Yavuz Bilge Ceylan";
  const [img, setImg] = useState();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [loading, setLoading] = useState(true);
  const handleClick = (index) => setSelectedIndex(index);

  useEffect(() => {
    fetchData();
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const fetchData = async () => {
    let list = [];
    try {
      const queryImg = await getDocs(collection(db, "gallery"));
      queryImg.forEach((doc) => list.push({ id: doc.id, ...doc.data() }));
      setImg(list);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="gallery">
      <h1>Photography</h1>
      <p>My favorite photographs from my camera.</p>
      {loading ? (
        <LoadScreen />
      ) : (
        <div className="images">
          {img?.map((item, index) => (
            <div key={index} className="img-cont" onClick={() => handleClick(index)}>
              <LazyLoad height={200}>
                <img src={item.src} alt="" />
              </LazyLoad>
            </div>
          ))}
        </div>
      )}
      <div>{selectedIndex > -1 && <Modal img={img} index={selectedIndex} setIndex={setSelectedIndex} />}</div>
    </div>
  );
}
