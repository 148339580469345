import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "personel-webpage-832f3.firebaseapp.com",
  projectId: "personel-webpage-832f3",
  storageBucket: "personel-webpage-832f3.appspot.com",
  messagingSenderId: "520222178263",
  appId: "1:520222178263:web:5a0f235e35330af1f5a992",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
