import "./contact.scss";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifyDefault = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

const initialValues = {
  name: "",
  email: "",
  phone: "",
  company: "",
  message: "",
};

const Contact = () => {
  document.title = "Contact | Yavuz Bilge Ceylan";
  const [inputs, setInput] = useState(initialValues);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidMessage, setIsValidMessage] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const form = useRef();

  const handleChange = (e) => {
    setInput({ ...inputs, [e.target.name]: e.target.value });
    if (e.target.name === "name") {
      setIsValidName(e.target.value);
    } else if (e.target.name === "message") setIsValidMessage(e.target.value);
  };

  // email regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleEmailChange = (e) => {
    setInput({ ...inputs, email: e.target.value });
    const isValidEmail = emailRegex.test(e.target.value);
    setIsValid(isValidEmail);
  };

  //send form
  const sendEmail = (e) => {
    e.preventDefault();
    if (!inputs.name && !inputs.message && !inputs.email) {
      setIsValidName(inputs.name);
      setIsValidMessage(inputs.message);
      setIsValid(inputs.email);
    } else if (isValidName && isValidMessage && isValid) {
      emailjs.sendForm("service_xphect8", "template_wd933go", form.current, "IBqJnX7ZBC9JFQqnU").then(
        () => {
          toast.success("Your message has been successfully delivered", notifyDefault);
          setInput(initialValues);
        },
        (error) => toast.error("Something went wrong.", notifyDefault)
      );
    }
    if (!inputs.name || !inputs.message) {
      toast.warning("Please fill in the required fields.", notifyDefault);
    } else if (!inputs.email || !isValid) {
      toast.warning("Please make sure the email format is correct.", notifyDefault);
    }
  };

  return (
    <div className="contact">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <h1>Contact Me</h1>
      <p>
        I'm available for freelancing. If you want something to be built or just to say hi, feel free to shoot me a
        message.
      </p>
      <form ref={form} onSubmit={sendEmail}>
        <div className="group">
          <input
            style={{ borderColor: !isValidName && "red" }}
            placeholder="Name *"
            name="name"
            value={inputs.name}
            onChange={handleChange}
          />
          <input
            style={{ borderColor: !isValid && "red" }}
            placeholder="Email *"
            name="email"
            value={inputs.email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="group">
          <input value={inputs.phone} placeholder="Phone (optional)" name="phone" onChange={handleChange} />
          <input value={inputs.company} placeholder="Company (optional)" name="company" onChange={handleChange} />
        </div>
        <textarea
          style={{ borderColor: !isValidMessage && "red" }}
          placeholder="Message *"
          name="message"
          value={inputs.message}
          onChange={handleChange}
        ></textarea>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default Contact;
