import { Tooltip } from "react-tooltip";
import "./footer.scss";
import { FaFacebookF, FaSpotify, FaTwitter, FaInstagram, FaGithub, FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="left">Yavuz Bilge Ceylan</div>
      {/* <div className="right">
        <a
          id="linkedin"
          href="https://www.linkedin.com/in/yavuz-bilge-ceylan-0753751a3/"
          className="icon-cont"
          rel="noreferrer"
          target="_blank"
        >
          <FaLinkedinIn className="icon" />
        </a>
        <a id="github" href="https://github.com/yvzblgcyln" className="icon-cont" rel="noreferrer" target="_blank">
          <FaGithub className="icon" />
        </a>
        <a
          id="instagram"
          href="https://www.instagram.com/yvzblgcyln/"
          className="icon-cont"
          rel="noreferrer"
          target="_blank"
        >
          <FaInstagram className="icon" />
        </a>
        <a
          id="spotify"
          href=" https://open.spotify.com/user/9g5ulspsjrd89pytx7ke402cq"
          className="icon-cont"
          rel="noreferrer"
          target="_blank"
        >
          <FaSpotify className="icon" />
        </a>

        <a id="twitter" href="https://twitter.com/yvzblgcyln" className="icon-cont" rel="noreferrer" target="_blank">
          <FaTwitter className="icon" />
        </a>
        <a
          id="facebook"
          href="https://www.facebook.com/yvzblgcyln"
          className="icon-cont"
          rel="noreferrer"
          target="_blank"
        >
          <FaFacebookF className="icon" />
        </a>
      </div> */}

      <Tooltip anchorId="linkedin" place="top" content="Linkedin" />
      <Tooltip anchorId="github" place="top" content="Github" />
      <Tooltip anchorId="instagram" place="top" content="Instagram" />
      <Tooltip anchorId="spotify" place="top" content="Spotify" />
      <Tooltip anchorId="twitter" place="top" content="Twitter" />
      <Tooltip anchorId="facebook" place="top" content="Facebook" />
    </div>
  );
};

export default Footer;
