import { useEffect, useState } from "react";
import "./blog.scss";
import Category from "../../components/category/Category";
import PostsLoader from "../../components/postsLoader/PostsLoader";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import LoadScreen from "../../components/loadScreen/LoadScreen";
import Posts from "../../components/posts/Posts";

function Blog() {
  document.title = "Blog | Yavuz Bilge Ceylan";
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("");
  const [filterType, setFilterType] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const filtered = blogs?.filter(({ tag, date, content1, content2, content3, content4, content5, title }) => {
    if (filterType === "category") {
      return tag.includes(activeTab);
    } else if (filterType === "archieve") {
      return date.split(".")[2].includes(activeTab);
    } else if (filterType === "search") {
      let filterText = content1 + content2 + content3 + content4 + content5 + title;
      return filterText.toLowerCase().includes(searchInput.toLowerCase());
    } else {
      return true;
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const queryBlog = await getDocs(collection(db, "blog"));
        queryBlog.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
      } catch (err) {
        console.log(err);
      }
      setBlogs(
        list.sort(function (a, b) {
          return a.id - b.id;
        })
      );
    };
    fetchData().finally(() => setLoading(false));
  }, []);

  return (
    <div className="blog df-col">
      <h1 id="header">My Blog</h1>
      {searchInput.length ? (
        <p>
          <b>{filtered.length}</b> {filtered.length > 1 ? "Posts" : "Post"} found as a result of searching{" "}
          <b>"{searchInput}"</b>
        </p>
      ) : (
        <p>This is my blog where my thoughts live. Check it out to know more about me.</p>
      )}
      {loading ? (
        <LoadScreen />
      ) : (
        <div className="blogs df-row">
          <div className="left df-col">
            {filtered.length === 0 ? <PostsLoader /> : <Posts filtered={filtered} blogs={blogs} />}
          </div>

          <div className="right df-col">
            <Category
              setSearchInput={setSearchInput}
              setFilterType={setFilterType}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              blogs={blogs}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Blog;
