import { Link } from "react-router-dom";
import { AiTwotoneLike, AiTwotoneDislike } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useContext } from "react";
import LikeContext from "../../context/LikeContext";
import LoadScreen from "../loadScreen/LoadScreenSmall";
import LazyLoad from "react-lazyload";

function Posts({ filtered, blogs }) {
  const { setBlogLength, liked, handleUpdateElement } = useContext(LikeContext);
  const [postCount, setPostCount] = useState(5);
  let maxPostCount = filtered.length;
  const [loading, setLoading] = useState(false);

  const loadMore = () => {
    setLoading(true);
    setTimeout(function () {
      postCount + 5 < maxPostCount ? setPostCount((prev) => prev + 5) : setPostCount(maxPostCount);
      setLoading(false);
    }, 1000);
  };

  const itemsToShow = filtered.slice(0, postCount);

  useEffect(() => {
    setBlogLength(blogs.length);
  }, []);

  return (
    <>
      {itemsToShow.map((blog) => (
        <div className="blog-container df-col " key={blog.id}>
          {blog.img && (
            <LazyLoad height={240}>
              <img src={blog.img} alt="" />
            </LazyLoad>
          )}
          <div className="info df-col ">
            <Link to={`/blog/post-${blog.id}/${blog.title.replace(/ /g, "-")}`} className="title">
              {blog.title}
            </Link>
            <div className="sub-info df-row">
              <div className="tag">#{blog.tag.replace(/ /g, " #")}</div>
              <div className="date">{blog.date}</div>
            </div>
          </div>
          <div className="text">
            <div dangerouslySetInnerHTML={{ __html: blog.content }}></div>
          </div>
          <div to="" className="button df-row">
            <Link to={`/blog/post-${blog.id}/${blog.title.replace(/ /g, "-")}`}> Read More</Link>
            <div className="icons">
              <AiTwotoneLike
                id={`like-${blog.id}`}
                className={`icon ${liked[blog.id] === "liked" ? "liked" : ""}`}
                onClick={() =>
                  liked[blog.id] === "liked" ? handleUpdateElement(blog.id, "") : handleUpdateElement(blog.id, "liked")
                }
              />
              <AiTwotoneDislike
                id={`dislike-${blog.id}`}
                className={`icon ${liked[blog.id] === "disliked" ? "disliked" : ""}`}
                onClick={() =>
                  liked[blog.id] === "disliked"
                    ? handleUpdateElement(blog.id, "")
                    : handleUpdateElement(blog.id, "disliked")
                }
              />
              {/* <Tooltip anchorId={`like-${blog.id}`} place="bottom" content="Like" />
              <Tooltip anchorId={`dislike-${blog.id}`} place="bottom" content="Dislike" /> */}
            </div>
          </div>
        </div>
      ))}
      {postCount !== maxPostCount && filtered.length > 5 && !loading && (
        <div className="loadBtn" onClick={loadMore}>
          Load More
        </div>
      )}
      {loading && (
        <div>
          <LoadScreen />
        </div>
      )}
    </>
  );
}

export default Posts;
